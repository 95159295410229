<template>
  <div class="module-youtube">
      <div class="video-container" v-if="module && module.resource && module.resource.content">
        <video controls width="100%" height="100%">
          <source :src="videoUrl" type="video/mp4">
        </video>
      </div>
  </div>
</template>

<script>

  export default {
    props: ['module'],
    data() {
      return {
        filelocation : process.env.VUE_APP_LOCAL_FILE_LOCATION
      }
    },
    computed : {
      videoUrl() {
        let url = this.module && this.module.resource && this.module.resource.content && this.module.resource.content.videoUrl ? this.module.resource.content.videoUrl : '';
        return url;
      },
      // fullscreen() {
      //   let fullscreen = !!this.module && !!this.module.resource && !!this.module.resource.content && this.module.resource.content.fullscreen == true ? true : false
      //   return fullscreen;
      // },
      // videoName() {
      //   let obj = url.parse(this.videoUrl);
      //   let name = obj.pathname.split("/")[obj.pathname.split("/").length - 1];
      //   return name;
      // }
    },
    mounted() {
    
      // if (this.fullscreen) {
      //   console.log("video module with fullscreen true");
      //   console.log("emiting play command");
      //   this.$socket.emit('play_video', this.videoName);
      // }
    }
  }
</script>

<style scoped>
  .module-youtube
  {
  }
  /* .video-container {
    position:relative;
    padding-bottom:56.25%;
    padding-top:0px;
    height:0;
    overflow:hidden;
  }
  .video-container iframe,
  .video-container object,
  .video-container embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  } */
</style>
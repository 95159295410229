var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.module.resource.content)?_c('div',{staticStyle:{"text-align":"center"},style:({  display: '', justifyContent: _vm.align(_vm.module.style.align), alignItems: _vm.valign(_vm.module.style.valign) })},[_c('div',{class:'flickity-carousel-' + _vm.randomSeed,staticStyle:{"height":"100%"}},[_vm._l((_vm.imagesToShow),function(image,index){return [_c('a',{staticClass:"flickity-carousel-cell",attrs:{"href":image.link}},[_c('img',{staticClass:"carousel-image",style:({
          // display: 'block',
          // border: module.resource.content.imageBorderSize + 'px solid',
          // borderColor: module.resource.content.imageBorderColor,
          height: '100%',
          width: '100%',
          // marginRight: '20px',
          objectFit : _vm.objectFit
        }),attrs:{"src":image.image,"alt":""}})])]})],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.module.resource.content)?_c('div',{staticClass:"module-text"},[_c('div',{ref:"module-text",staticClass:"content is-large",class:[
      _vm.fontFamily
      ],style:({
      color     : _vm.module.resource.content.fontColor,
      fontSize  : _vm.autoFontSize,
      textAlign : _vm.module.resource.content.textAlign,
      height:'100%',
      maxHeight : '100%'
      }),domProps:{"innerHTML":_vm._s(_vm.module.resource.text)}})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>

  <div
    v-if="module.resource.content"
    class="image-holder"
    style="width:100%;height:100%;overflow:hidden;">
      <img
        class="preview"
        :src="module.resource.content.selectedCam.url"
        :style="{
          animationDuration: `${module.resource.content.animationDuration}s`,
          animationDirection: `${module.resource.content.animationDirection}`,
          height:`100%`,
          maxWidth:`100%`,
          objectFit:`cover`,
          objectPosition:`0%`,
        }">
  </div>

</template>

<script>
  export default {
    props: ['module'],
    data() {
      return {
      }
    },
    methods: {
      align(val) {
        if (!val) { return 'center' }
        if (val == 'flex-start') {
          return 'start'
        } else if (val == 'flex-end') {
          return 'end'
        } else {
          return 'center'
        }
      },
      valign(val) {
        if (!val) { return 'center' }
        if (val == 'flex-start') {
          return 'start'
        } else if (val == 'flex-end') {
          return 'end'
        } else {
          return 'center'
        }
      }
    }
  }
</script>

<style scoped>

  .image-holder {
    padding: 0px !important;
  }

 .preview {
    animation: linear infinite;
    animation-name: scrollImage;
    min-height:400px;
    max-height:450px;
  }
  @keyframes scrollImage {
    0% {
      object-position: 0;
    }
    100% { 
      object-position: 100%; 
    }
    /* 100% {
      object-position: 0;     
    } */
  }
</style>
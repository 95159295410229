<template>

  <div
    v-if="module.resource.content"
    style="text-align:center"
    :style="{  display: '', justifyContent: align(module.style.align), alignItems: valign(module.style.valign) }">

    <!-- <div class="flickity-carousel" >
      <img
        v-for="(image, index) in imagesToShow"
        class="flickity-carousel-cell"
        :src="image.image"
        alt=""
        :style="{
          display: 'block',
          border: module.resource.content.imageBorderSize + 'px solid',
          borderColor: module.resource.content.imageBorderColor,
          height: '500px',
          marginRight: '20px',
          objectFit : objectFit
        }"
      />
    </div> -->

    <div :class="'flickity-carousel-' + randomSeed" style="height:100%">
      <template v-for="(image, index) in imagesToShow">

        <a :href="image.link" class="flickity-carousel-cell">
          <img
          class="carousel-image"
          :src="image.image"
          alt=""
          :style="{
            // display: 'block',
            // border: module.resource.content.imageBorderSize + 'px solid',
            // borderColor: module.resource.content.imageBorderColor,
            height: '100%',
            width: '100%',
            // marginRight: '20px',
            objectFit : objectFit
          }"
        />
      </a>

      </template>
    </div>

  </div>

</template>

<script>
  import Flickity from 'flickity';
  import _ from 'lodash'

  export default {
    props: ['module'],
    data() {
      return {
        interval : null,
        currentImageIndex : 0,
        originalImageUrls : this.module.resource.content.imageUrls,
        originalImageLinks : this.module.resource.content.imageLinks ? this.module.resource.content.imageLinks : [],
        imagesToShow : [],
        flickityInstance : null,
        randomSeed : Date.now()
      }
    },
    computed : {
      objectFit() {
        if (this.module && this.module.resource && this.module.resource.content && this.module.resource.content.imageSizeStyle) {
          let imageSizeStyle = this.module.resource.content.imageSizeStyle;
          return imageSizeStyle == 'default' ? 'contain' : imageSizeStyle == 'proportionally-scaled' ? 'cover' : 'fill'
        } else {
          return 'contain'
        }
      }
    },
    methods: {
      align(val) {
        if (!val) { return 'center' }
        if (val == 'flex-start') {
          return 'start'
        } else if (val == 'flex-end') {
          return 'end'
        } else {
          return 'center'
        }
      },
      valign(val) {
        if (!val) { return 'center' }
        if (val == 'flex-start') {
          return 'start'
        } else if (val == 'flex-end') {
          return 'end'
        } else {
          return 'center'
        }
      },
      startSwitcher() {
        let speed = this.module.resource.content && this.module.resource.content.imageGallerySpeed ? this.module.resource.content.imageGallerySpeed : 4000;
        if (this.imagesToShow.length > 0) {
          this.interval = setInterval(() => {
            this.currentImageIndex = (this.currentImageIndex + 1) % this.imagesToShow.length;
          }, speed);
        }
      },
      filterInactiveImages() {
        let images = this.originalImageUrls;
        let links = this.originalImageLinks;
        let dates = this.module.resource.content.imageActiveUntil ? this.module.resource.content.imageActiveUntil : [];
        let today = new Date().setHours(0,0,0,0);
        let filteredImages = [];
        images.map((image, index) => {
          if (dates[index]) {
            if (new Date(dates[index]) > today) {
              filteredImages.push({ image , link : links[index] });
            }
          } else {
            filteredImages.push({ image , link : links[index] });
          }
        });
        const random = this.module.resource.content.imageOrderRandom;
        this.imagesToShow = random ? _.shuffle(filteredImages) : filteredImages;
      }
    },
    mounted() {
      clearInterval(this.interval);
      this.filterInactiveImages();
      // this.startSwitcher();

      setTimeout(() => {
        this.flickityInstance = new Flickity(document.querySelector('.flickity-carousel-' + this.randomSeed), {
          accessibility: false,
          autoPlay: this.module.resource.content.imageGallerySpeed || false,
          cellAlign: 'center',
          draggable: '>1',
          dragThreshold: 3,
          friction: 0.2,
          groupCells: false,
          initialIndex: 0,
          percentPosition: false,
          prevNextButtons: true,
          pageDots: true,
          rightToLeft: false,
          setGallerySize: false,
          watchCSS: false,
          wrapAround: false
        });
      },500);
      

    },
    beforeDestroy() {
      clearInterval(this.interval);
    }
  }
</script>

<style scoped>



.flickity-carousel-cell {
  width: 100%;
  height: 100%;
  margin-right: 10px;
  /* background: #8C8;
  border-radius: 5px;
  counter-increment: gallery-cell; */
}

/* cell number */

</style>
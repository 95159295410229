<template>
  <div
    class="module-text"
    v-if="module.resource.content"

    >
    <div
      v-html="module.resource.text"
      class="content is-large"
      :class="[
        fontFamily
        ]"
      :style="{
        color     : module.resource.content.fontColor,
        fontSize  : autoFontSize,
        textAlign : module.resource.content.textAlign,
        height:'100%',
        maxHeight : '100%'
        }"
      ref="module-text"
        >
    </div>
  </div>

</template>

<script>
  export default {
    props: ['module'],
    data() {
      return {
        fontclass: {
          slab: 'font-slab',
          script: 'font-script',
          default: 'font-default'
        },
        size: {
          small : 80,
          standard: 120,
          large: 250
        },
        autoFontSize: '20px'
      }
    },
    computed: {
      fontFamily() {
        let style = this.module.resource.content.fontStyle;
        return this.fontclass[style];
      },
      fontSize() {
        let value = this.module.resource.content.textSize;
        return this.size[value];
      }
    },
    methods: {
      autosizetext() {
        let element       = this.$refs["module-text"];
        let divWidth      = element.clientWidth;
        let divHeight     = element.clientHeight;
        let contentWidth  = element.scrollWidth;
        let contentHeight = element.scrollHeight;
        let fontSize      = element.style.fontSize;
        fontSize = Number(fontSize.substring(0, fontSize.length - 2));
        while (contentWidth <= divWidth && contentHeight <= divHeight) {
          fontSize += 1;
          element.style.fontSize = fontSize + 'px';
          contentWidth  = element.scrollWidth;
          contentHeight = element.scrollHeight;
        }
        while (contentWidth > divWidth || contentHeight > divHeight) {
          fontSize -= 1;
          element.style.fontSize = fontSize + 'px';
          contentWidth = element.scrollWidth;
          contentHeight = element.scrollHeight;
        }
      }
    },
    mounted() {
      this.autosizetext();
    }
  }
</script>

<style scoped>
  .module-text {
    /* display:flex;
    align-items: center;
    justify-content: center; */
  }
  .font-slab {
    font-family: 'Roboto Slab', serif;
  }
  .font-default {
    font-family: 'GraebenbachOE_Regular', sans-serif;
  }
  .font-modern {

  }
  .font-script {
    font-family: 'Kaushan Script', cursive;
  }

</style>
<template>

  <div
    v-if="module.resource.content"
    style="text-align:center"
    :style="{  display: '', justifyContent: align(module.style.align), alignItems: valign(module.style.valign) }">
      <img
        :src="module.resource.content.imageUrl"
        alt=""
        style="max-height:100%; max-width:100%"
        :style="{
          border: module.resource.content.imageBorderSize + 'px solid',
          borderColor: module.resource.content.imageBorderColor,
          objectFit : 'contain'
        }">
  </div>

</template>

<script>
  export default {
    props: ['module'],
    data() {
      return {
      }
    },
    methods: {
      align(val) {
        if (!val) { return 'center' }
        if (val == 'flex-start') {
          return 'start'
        } else if (val == 'flex-end') {
          return 'end'
        } else {
          return 'center'
        }
      },
      valign(val) {
        if (!val) { return 'center' }
        if (val == 'flex-start') {
          return 'start'
        } else if (val == 'flex-end') {
          return 'end'
        } else {
          return 'center'
        }
      }
    }
  }
</script>

<style scoped>
</style>
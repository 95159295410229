<template>
  <div class="module-youtube">
      <div class="video-container">
        <iframe
          id="ytplayer"
          type="text/html"
          :src="
            'https://www.youtube.com/embed/' + module.resource.content.videoId + '?autoplay=0&mute=0&controls=1&disablekb=1&fs=0&playlist='+module.resource.content.videoId+'&loop=1&modestbranding=1&iv_load_policy=3&version=3'"
          frameborder="0"
        ></iframe>
      </div>
  </div>
</template>

<script>
  export default {
    props: ['module'],
    data() {
      return {
      }
    }
  }
</script>

<style scoped>
  .module-youtube
  {
  }
  .video-container {
    position:relative;
    padding-bottom:56.25%;
    padding-top:0px;
    height:0;
    overflow:hidden;
  }
  .video-container iframe,
  .video-container object,
  .video-container embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
</style>
<template>
  <div class="module-weather">

    <!-- IF NO ERROR -->
    <div class="inner" v-if="!error && data">

      <!-- CURRENT WEATHER -->
      <div class="current">
        <div class="current-temp">
          {{ Math.round(data.current.temp) }}°
        </div>
        <div class="current-icon">
          <img :src="require(`@/assets/weather-icons/${data.current.icon}.svg`)" />
        </div>
        <div class="current-status">
          <p>{{ module.resource.content.name }}</p>
          <p>{{ data.current.description }}</p>
        </div>
      </div>

      <!-- FORECAST -->
      <div class="forecast">

        <div class="day" v-for="(day, index) in data.daysAfter">
          <div class="day-name">
            <p>{{getDayOfDate(day.time)}}</p>
          </div>
          <div class="day-icon">
            <img :src="require(`@/assets/weather-icons/${day.icon}.svg`)" />
          </div>
          <div class="day-temp">
            {{ Math.round(day.temp) }}°
          </div>
        </div>

      </div>
    </div>

    <!-- IF ERROR  -->
  </div>
</template>

<script>

  export default {
    props: ["module"],
    data() {
      return {
        data  : null,
        error : false,
        dayAbbr : {
          de : ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
          en : ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        },
        language : 'de'
      }
    },
    computed: {
    },
    methods : {
      getDayOfDate(date) {
        let day = new Date(date.replace(/-/g, "/"));
        day = day.getDay();
        return this.dayAbbr[this.language][day] ? this.dayAbbr[this.language][day] : "";
      }
    },
    mounted() {
      const zip     = this.module.resource.content.zip;
      const country = this.module.resource.content.country;
      const language = this.module.resource.content.language ? this.module.resource.content.language : 'en';
      this.language = language;

      this.$axios.get(`https://neptoon.herokuapp.com/api/v1/weather?zip=${zip}&country=${country}&lang=${language}`)
        .then(r => {
          this.data  = r.data.payload;
          this.error = false;
        })
        .catch(e => {
          this.error = true;
        });
    },
    beforeDestroy() {
    }
  }
</script>

<style lang="css" scoped>
  .module-weather {
    /* font-family: 'Heebo', sans-serif; */
    color:white;
  }
  .module-weather .inner {
    /* height:calc(100% - 20px); */
    padding:10px;
    overflow: hidden;
  }
  .current {
    display:flex;
    width:100%;
    align-items: center;
    justify-content: center;
    background:rgba(0,0,0,0.4);
    border-radius:5px;
    height:65px;
  }
  .current-temp {
    font-size   : 45px;
    font-weight : 900;
    text-align: center;
  }
  .current-icon {
    text-align:center;
    height:65px;
    margin-left:20px;
    margin-right:20px;
    display:flex;
    align-items: center;
  }
  .current-icon img {
    height:50px;
  }
  .current-status {
    text-align:center;
    font-size:18px;
  }
  .forecast {
    margin-top:5px;
    display: flex;
  }
  .forecast .day {
    text-align: center;
    background:rgba(0,0,0,0.4);
    border-radius:5px;
    flex:1;
    padding-top:5px;
    /* min-height:180px; */
  }
  .forecast .day:first-child {
    margin-right:5px;
  }
  .forecast .day:last-child {
    margin-left:5px;
  }
  .day-name {
    font-size: 16px;
  }
  .day-icon {
    margin-top:10px;
  }
  .day-icon img {
    height:50px;
  }
  .day-temp {
    font-weight: 600;
    font-size: 20px;
    margin-top:0px;
    margin-bottom:10px;
  }
</style>
<template>
  <div
    class="module-tourdata-multi"
    v-if="module.resource.content"
    >

    <!-- HEADER IMAGE -->
    <div
      class="image-header"
      v-if="module.resource.content && module.resource.content.topImage"
      :style="{
        background : `url(${module.resource.content.topImage})`,
        backgroundSize : 'cover',
        backgroundRepeat : 'no-repeat',
        backgroundColor : 'black',
        backgroundPosition : 'center'
        }"
      >
    </div>

    <!-- HEADER TEXT -->
    <div class="text-header">
      <div v-html="module.resource.text"></div>
    </div>

    <!-- EVENTS BOX STYLE -->
    <template v-if="module.resource.content && module.resource.content.queryStyle == 'box'">
      <a v-for="(event,index) in availableEvents" :key="index" :href="`https://wolfgangsee.salzkammergut.at/veranstaltungen/oesterreich-veranstaltung/detail/${event.id}/${event.titleUrlSafe}`">
        <div class="event-box">
          <div class="columns">
            <div class="column">
              <p class="event-box-header"> {{ event.ort ? event.ort : event.bezirk }} </p>
            </div>
          </div>
          <div class="columns">
            <div class="column event-box-left">
              <p class="event-box-date">{{ beautifyDate2(event.termin_next) }}</p>
              <p class="event-box-title">{{ event.title }}</p>
              <p class="event-box-ort">{{ event.veranstaltungsort.name }}</p>
            </div>
          </div>
        </div>
      </a>
    </template>

    <!-- EVENTS LIST STYLE -->
    <template v-else>

      <a v-for="(event,index) in availableEvents" :key="index" :href="`https://wolfgangsee.salzkammergut.at/veranstaltungen/oesterreich-veranstaltung/detail/${event.id}/${event.titleUrlSafe}`">
        <p class="event-list-item" :style="{fontSize:`${module.resource && module.resource.content && module.resource.content.fontSize ? module.resource.content.fontSize : 12}px`}">
          <strong>{{ beautifyDate2(event.termin_next) }}</strong><br />
          {{event.title}} - {{ event.veranstaltungsort.name }}
        </p>
      </a>

    </template>

  </div>
</template>

<script>
  import _ from 'lodash';

  import dateFormat from 'dateformat';
  import { i18n } from "dateformat";

  i18n.dayNames = [
    'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa',
    'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'
  ];
  i18n.monthNames = [
    'Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez',
    'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
  ];
  
  i18n.timeNames = [
    'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
  ];
  
  export default {
    props: ['module'],
    data() {
      return {
        availableEvents : [],
        interval : null,
        currentSwitchIndex : 0,
      }
    },
    computed : {
      availableEventsChunked() {
        return _.chunk(this.availableEvents, 3)
      }
    },
    methods: {
      requestTourData() {
        let filterString = "?";
        if (this.module.resource && this.module.resource.content) {
          let c = this.module.resource.content;
          if (c.queryPLZ)      { filterString += "plz=" + c.queryPLZ + "&"; }
          if (c.queryStart)    { filterString += "start=" + c.queryStart + "&"; }
          if (c.queryEnd)      { filterString += "end=" + c.queryEnd + "&"; }
          if (c.queryCategory) { filterString += "category=" + c.queryCategory.join(',') + "&"; }
          if (c.queryFeatured) { filterString += "featured=" + c.queryFeatured + "&"; }
          if (c.queryLanguage) { filterString += "lang=" + c.queryLanguage + "&"; }
          filterString += c.maxEvents ? "limit=" + c.maxEvents : c.queryStyle == 'box' ? "limit=6" : "limit=12";
        }
        filterString = filterString.length > 1 ? filterString : "";
        this.$axios.get(`https://neptoon.herokuapp.com/api/v1/tourdata` + filterString)
        .then(r => {
          this.availableEvents = r.data.payload.events;
          this.startSwitcher();
        })
        .catch(e => {
        });
      },
      beautifyDate(str) {
        let date = new Date(str);
        return dateFormat(date, "dddd, d. mmmm, yyyy")
      },
      beautifyDate2(str) {
        console.log(str);
        let date = new Date(str);
        console.log(date);
        return dateFormat(date, "ddd, d. mmmm yyyy, HH:MM")
      },
      startSwitcher() {
        if (this.module.resource.content && this.module.resource.content.queryStyle && this.module.resource.content.queryStyle == 'box') {
          this.interval = setInterval(() => {
            this.currentSwitchIndex = (this.currentSwitchIndex + 1) % this.availableEventsChunked.length;
          }, 10000);
        }
      }
    },
    mounted() {
      clearInterval(this.interval);
      this.requestTourData();
    },
    beforeDestroy() {
      clearInterval(this.interval);
    }
  }
</script>

<style scoped>

a:active {
  color:inherit;
      text-decoration: none;
}

a:link {
  color:inherit;
      text-decoration: none;
}

a:visited {
  color:inherit;
      text-decoration: none;
}

a:hover {
  color:inherit;
      text-decoration: none;
}

a:active {
      color:inherit;
      text-decoration: none;
}

  .module-tourdata-multi {
    /* font-family: 'Heebo'; */
    display:flex;
    flex-direction: column;
  }
  .event-box-header {
    background:#f6e209;
    font-weight: 700;
    text-align: center;
    font-size: 0.95em;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding:6px 0 6px 0;
    margin:0px 0px;
    border-radius: 5px;
  }
  .event-box-date {
    color:#777;
    text-align: center;
    font-size: 1.3em;
  }
  .event-box-title {
    font-weight: bold;
    text-align: center;
    font-size: 1.9em;
    margin: 0 0;
    line-height: 1.2em;
  }
  .event-box-ort {
    font-weight:400;
    text-align: center;
  }
  .image-header {
    flex:auto;
    max-height: 40%;
    min-height: 200px;
  }
  .text-header {
    text-align: center;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:25px;
    color:#777;
    line-height: 1.2em;
    margin-top:40px;
    margin-bottom:30px;
    margin-left:0px;
    margin-right:0px;
  }
  .event-box {
    flex:auto;
    margin-bottom:25px;
  }
  .event-list {
    margin: 0px 5px;
    margin-bottom: 5px;
  }
  .event-list-item {
    margin-bottom:20px;
    line-height: 1.1em;
    /* font-size:0.8em; */
  }
</style>
<template>

  <div
    class="marquee-container"
    v-if="module.resource.content"
    :style="{
        fontSize : `${module.resource.content.size}px`,
        color : `${module.resource.content.color}`
      }">
    <marquee-text :duration="module.resource.content.speed" :repeat="10">
      {{ module.resource.content.textPieces.join(" " + `${module.resource.content.symbole}` + " ") }} {{ module.resource.content.symbole }} &zwnj; 
    </marquee-text>
  </div>
  <!-- <div>
    {{ module.resource.content }}
    </div> -->

</template>

<script>
  import MarqueeText from 'vue-marquee-text-component'
  export default {
    props: ['module'],
    data() {
      return {
      }
    },
    components : {
      MarqueeText
    },
    computed : {
      // textPieces() { return module.resource.content.textPieces },
      // symbole()    { return module.resource.content.symbole    },
      // speed()      { return module.resource.content.speed      },
      // size()       { return module.resource.content.size       },
      // color()      { return module.resource.content.color      }
    },
    methods: {
    }
  }
</script>

<style scoped>
  .marquee-container {
    max-width : 100%;
    padding : 20px;
    overflow : hidden;
    white-space: no-wrap;
    display : flex;
    align-items: center;
  }
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.module.resource.content)?_c('div',{staticClass:"image-holder",staticStyle:{"width":"100%","height":"100%","overflow":"hidden"}},[_c('img',{staticClass:"preview",style:({
        animationDuration: ((_vm.module.resource.content.animationDuration) + "s"),
        animationDirection: ("" + (_vm.module.resource.content.animationDirection)),
        height:"100%",
        maxWidth:"100%",
        objectFit:"cover",
        objectPosition:"0%",
      }),attrs:{"src":_vm.module.resource.content.selectedCam.url}})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
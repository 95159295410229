<template>
  <div class="module-date-time">
    <div class="columns" style="height:100%;">

      <div class="column" v-if="module.resource.content && module.resource.content.showDate" style="text-align:center;display:flex;justify-content:center;align-items:center;">
        <div class="h1 title has-text-white">
          <span class="day">{{ day }}</span></br> {{ date }}
        </div>
      </div>

      <div class="column" v-if="module.resource.content && module.resource.content.showTime" style="text-align:center;display:flex;justify-content:center;align-items:center;">
        <div class="h1 title has-text-white">
          <span class="time">{{ time }}</span>
        </div>
      </div>


    </div>
  </div>
</template>

<script>

  import dateFormat from 'dateformat';
  import { i18n } from "dateformat";

  i18n.dayNames = [
    'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa',
    'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'
  ];
  i18n.monthNames = [
    'Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez',
    'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
  ];
  i18n.timeNames = [
    'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
  ];

  export default {
    props: ["module"],
    data() {
      return {
        interval : null,
        time : dateFormat(new Date(), "HH:MM")
      }
    },
    computed: {
      day() {
        return dateFormat(new Date(), "ddd").toUpperCase();
      },
      date() {
        return dateFormat(new Date(), "d. mmmm")
      }
    },
    mounted() {
      this.interval = setInterval(() => {
        this.time = dateFormat(new Date(), "HH:MM");
      }, 10000)
    },
    beforeDestroy() {
      clearInterval(this.interval);
    }
  }
</script>

<style lang="css" scoped>
  .module-date-time {
    /* font-family: 'Heebo'; */
    text-shadow: 0px 0px 5px rgba(0,0,0,0.3);
  }
  .day {
    font-size:3em;
  }
  .time {
    font-size:2.5em;
  }
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register",style:({
    backgroundImage : 'url(' + _vm.asset.style.backgroundImg + ')',
    backgroundColor : _vm.objectToRgbaString(_vm.asset.style.backgroundRgba),
  })},[_vm._l((_vm.asset.children),function(child,index){return [_c('div',{key:child.id,staticClass:"entry",on:{"click":function($event){return _vm.followLink(index)}}},[(child)?_c(child.resource.module,{tag:"component",staticClass:"module",style:({
          backgroundColor : _vm.objectToRgbaString(child.style.backgroundRgba),
          backgroundImage : 'url(' + child.style.backgroundImg + ')',
          padding         : child.style.innerSpace + 'px',
          height: ("" + (_vm.asset.sectionHeights[index] ? _vm.asset.sectionHeights[index] + 'px' : 'inherit'))
          // margin          : page.children[index].style.outerSpace + 'px',
          // maxWidth        : page.children[index].style.maxWidth + '%',
          // minWidth        : page.children[index].style.maxWidth + '%',
          // maxHeight       : page.children[index].style.maxHeight + '%',
          // boxShadow : produceBoxShadowString(page.positionStyles[index])
        }),attrs:{"module":child}}):_vm._e()],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
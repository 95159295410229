<template>
  <div class="module-iframe" v-if="module.resource && module.resource.content && module.resource.content.iframeString">
    <div v-html="module.resource.content.iframeString">

    </div>
  </div>
</template>

<script>
  export default {
    props: ['module'],
    data() {
      return {
      }
    }
  }
</script>

<style lang="css">
  .module-iframe div {
    height : 100%;
  }
  .module-iframe div iframe {
    height : 100% !important;
  }
</style>
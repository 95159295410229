<template>
  <div class="register"
    :style="{
      backgroundImage : 'url(' + asset.style.backgroundImg + ')',
      backgroundColor : objectToRgbaString(asset.style.backgroundRgba),
    }"
  >
    <template v-for="child, index in asset.children">
      <div
        class="entry"
        :key="child.id"
        @click="followLink(index)"
        >

        <component
          v-if="child"
          :is="child.resource.module"
          :module="child"
          class="module"
          :style="{
            backgroundColor : objectToRgbaString(child.style.backgroundRgba),
            backgroundImage : 'url(' + child.style.backgroundImg + ')',
            padding         : child.style.innerSpace + 'px',
            height: `${asset.sectionHeights[index] ? asset.sectionHeights[index] + 'px' : 'inherit'}`
            // margin          : page.children[index].style.outerSpace + 'px',
            // maxWidth        : page.children[index].style.maxWidth + '%',
            // minWidth        : page.children[index].style.maxWidth + '%',
            // maxHeight       : page.children[index].style.maxHeight + '%',
            // boxShadow : produceBoxShadowString(page.positionStyles[index])
          }" />

      </div>
    </template>
  </div>
</template>

<script>
// @ is an alias to /src

import axios from 'axios'

import Standard       from '@/components/modules/ModuleText';
import ModuleImage    from '@/components/modules/ModuleImage';
import ModuleVideo    from '@/components/modules/ModuleVideo';
import ModuleImageGallery    from '@/components/modules/ModuleImageGallery';
import ModuleText     from '@/components/modules/ModuleText';
import ModuleYoutube  from '@/components/modules/ModuleYoutube';
import ModuleTourdataMulti from '@/components/modules/ModuleTourdataMulti';
import ModuleIframe from '@/components/modules/ModuleIframe';
import ModuleDateTime from '@/components/modules/ModuleDateTime';
import ModuleWeather from '@/components/modules/ModuleWeather';
import ModuleNewsTicker from '@/components/modules/ModuleNewsTicker';
import ModulePanomaxWebcam from '@/components/modules/ModulePanomaxWebcam';

export default {
  name: 'RegisterView',
  data() {
    return {
      asset : {
        style : {
          backgroundImg : "",
          backgroundRgba : ""
        }
      }
    }
  },
  components: {
    Standard,
    ModuleImage,
    ModuleVideo,
    ModuleImageGallery,
    ModuleText,
    ModuleYoutube,
    ModuleTourdataMulti,
    ModuleIframe,
    ModuleDateTime,
    ModuleWeather,
    ModuleNewsTicker,
    ModulePanomaxWebcam
  },
  methods : {
    objectToRgbaString(obj) {
      const { r, g, b, a } = obj;
      return `rgba(${r}, ${g}, ${b}, ${a})`
    },
    followLink(index) {
      const url = this.asset.pageLinks[index];
      if (url) {
        window.location.href = url;
      }
    }
  },
  async mounted() {

      // document.querySelector('link[rel=manifest]').href = '/manuskript.json?shortid=' + this.$route.params.shortid;
    const response = await axios.get(`https://jupiterdatabase.herokuapp.com/api/v2/preview/${this.$route.params.shortid}`);
    this.asset = response.data.payload.asset;

    const pwa = this.asset.pwa;
    let iconQuery = "";
    if (pwa.icon512) { iconQuery += "&icon512=" + pwa.icon512; }
    if (pwa.icon192) { iconQuery += "&icon192=" + pwa.icon192; }
    if (pwa.icon180) { iconQuery += "&icon180=" + pwa.icon180; }
    if (pwa.icon120) { iconQuery += "&icon120=" + pwa.icon120; }

    const link = document.createElement('link');
    link.href = '/manuskript.json?shortid=' + this.$route.params.shortid + '&name=' + this.asset.meta.name + `${ iconQuery =! '' ? iconQuery : ''}`;
    link.rel = 'manifest';
    document.getElementsByTagName('head')[0].appendChild(link);

    document.title = this.asset.meta.name;

    console.log(this.asset)

  }
}
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css?family=Kaushan+Script|Roboto+Slab');
@import url('https://fonts.googleapis.com/css?family=Heebo:300,400,700');

  .register {
    min-height : 100vh;
    padding:10px;

  }

  .entry {
    background: rgba(0,0,0,0.1);
    border-radius: 4px;
    margin-bottom:10px;
    padding:10px;
  }

  .entry:last-child {
    margin-bottom: 100px;
  }

  .module {
    overflow:hidden;
    // height:100%;
    // width:100%;
  }

</style>
